import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaArrowLeft, FaFileDownload } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment-timezone";
import { ApiUrl } from "../../components/API/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const RetiredPriestDetails = () => {
  const search = useLocation().search;
  const encryptedId = new URLSearchParams(search).get("rpriestid");
  const rpriestid = parseInt(atob(decodeURIComponent(encryptedId)));
  const [error, setError] = useState(null);
  const [selectedRetiredPriest, setSelectedRetiredPriest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupImage, setPopupImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setPopupImage(imageUrl);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setPopupImage(null);
  };

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchPriestData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${ApiUrl}/get/kurnooldiocese/preist/NaN`
        );
        const priestData = response.data.data || [];
        const selected = priestData.find(
          (item) => item.category_id === 11 && item.id === rpriestid
        );
        setSelectedRetiredPriest(selected || null);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchPriestData();
  }, [rpriestid]);

  const convertToIndianDateFormat = (dateString) => {
    return moment(dateString).tz("Asia/Kolkata").format("D MMMM YYYY");
  };

  const downloadPdf = () => {
    const pdf = new jsPDF({
      orientation: "portrait",
    });

    const header = "Priest Details";
    pdf.setFontSize(20);
    pdf.text(header, pdf.internal.pageSize.getWidth() / 2, 15, "center");

    const filename = `${selectedRetiredPriest.name}_details.pdf`;

    const tableData = [
      ["Name", selectedRetiredPriest.name],
      [
        "Date of Birth",
        convertToIndianDateFormat(selectedRetiredPriest.date_of_birth),
      ],
      [
        "Ordination Date",
        convertToIndianDateFormat(selectedRetiredPriest.date_of_ordination),
      ],
      ["Email", selectedRetiredPriest.email],
      ["Phone Number", selectedRetiredPriest.phone],
      ["Residence", selectedRetiredPriest.residence],
      ["Roles", selectedRetiredPriest.roles],
    ];

    // Set page border
    pdf.setLineWidth(1);
    pdf.rect(
      5,
      5,
      pdf.internal.pageSize.getWidth() - 10,
      pdf.internal.pageSize.getHeight() - 10
    );

    pdf.autoTable({
      startY: 30,
      head: [["Field", "Details"]],
      body: tableData,
      theme: "grid",
      styles: {
        fontSize: 12,
        cellPadding: 5,
        overflow: "linebreak",
      },
      columnStyles: {
        0: { fontStyle: "bold", fillColor: "#f2f2f2" },
        1: {},
      },
    });

    const footer = "Copyright © Diocese of Kurnool";
    const printDateTime = `Printed on: ${new Date().toLocaleString()}`;
    pdf.setFontSize(10);
    pdf.text(printDateTime, 10, pdf.internal.pageSize.getHeight() - 10);
    pdf.text(
      footer,
      pdf.internal.pageSize.getWidth() / 2,
      pdf.internal.pageSize.getHeight() - 10,
      "center"
    );

    pdf.save(filename);
  };

  const badgeColors = [
    "badge-primary",
    "badge-success",
    "badge-danger",
    "badge-warning",
    "badge-info",
    "badge-light",
    "badge-dark",
  ];

  return (
    <>
      <div className="container mt-5">
        {loading && (
          <div className="text-center mt-3">
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />{" "}
          </div>
        )}
        {error && <p>Error: {error?.message}</p>}
        {selectedRetiredPriest && (
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9">
                  <h4
                    className="heading text-left mb-4 ml-0"
                    style={{ fontFamily: "Montserrat" }}
                  >
                    {selectedRetiredPriest?.name}
                  </h4>
                  <dl className="row">
                    <div className="col-sm-6">
                      <dt>Priest Name:</dt>
                      <dd>{selectedRetiredPriest?.name}</dd>

                      <dt>DOB:</dt>
                      <dd>
                        {convertToIndianDateFormat(
                          selectedRetiredPriest?.date_of_birth
                        )}
                      </dd>

                      <dt>Ordination Date:</dt>
                      <dd>
                        {convertToIndianDateFormat(
                          selectedRetiredPriest?.date_of_ordination
                        )}
                      </dd>

                      <dt>Roles:</dt>
                      <dd>
                        {selectedRetiredPriest?.roles
                          ?.split(",")
                          ?.map((role, index) => (
                            <span
                              key={index}
                              className={`badge ${
                                badgeColors[index % badgeColors.length]
                              }`}
                              style={{ marginRight: "5px" }}
                            >
                              {role?.trim()}
                            </span>
                          ))}
                      </dd>
                    </div>

                    <div className="col-sm-6">
                      <dt>Email:</dt>
                      <dd>
                        <a
                          href={`mailto:${selectedRetiredPriest?.email}`}
                          style={{ textDecoration: "none" }}
                        >
                          {selectedRetiredPriest?.email}
                        </a>
                      </dd>

                      <dt>Phone Number:</dt>
                      <dd>
                        <a
                          href={`tel:${selectedRetiredPriest?.phone}`}
                          style={{ textDecoration: "none" }}
                        >
                          {selectedRetiredPriest?.phone}
                        </a>
                      </dd>

                      <dt>Residence:</dt>
                      <dd>{selectedRetiredPriest?.residence}</dd>
                    </div>
                  </dl>
                  <button
                    className="btn btn-primary btn-sm"
                    title="Go Back"
                    onClick={goBack}
                  >
                    <FaArrowLeft />
                  </button>
                  <button
                    onClick={downloadPdf}
                    className="btn btn-success btn-sm"
                    style={{ marginLeft: "10px" }}
                    title="Download PDF"
                  >
                    <FaFileDownload />
                  </button>
                </div>

                <div className="col-lg-3 text-center">
                  <img
                    src={selectedRetiredPriest?.media_url}
                    alt="Priest"
                    className="img-fluid mt-4"
                    width="100"
                    height="150"
                    onClick={() =>
                      handleImageClick(selectedRetiredPriest?.media_url)
                    }
                    style={{ cursor: "pointer" }}
                  />
                  {popupVisible && (
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                      }}
                      onClick={closePopup}
                    >
                      <div
                        style={{
                          position: "relative",
                          backgroundColor: "white",
                          padding: "20px",
                          borderRadius: "8px",
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <img
                          src={popupImage}
                          alt="Popup Priest"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RetiredPriestDetails;
