import { useEffect, useState } from "react";
import { ApiUrl } from "./components/API/Api";
import Youtube from "./components/Youtube";
import About from "./components/about";
import Footer from "./components/footer";
import Header from "./components/header";
import Scrollbar from "./components/scrollbar";
import Slider from "./components/slider";
import axios from "axios";
import LatestNews from "./components/LatestNews";
import { PulseLoader } from "react-spinners";
import HomeSections from "./components/homesections";
import Whoweare from "./components/Whoweare";
import Borderfooter from "./components/Borderfooter";
import GalleryImages from "./components/Gallery";

function Home() {
  const [isLoading, setLoading] = useState(true);
  const [homedata, setHomedata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <PulseLoader
            color="#0d4571"
            loading={isLoading}
            size={12}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      {!isLoading && (
        <>
          <Header menudata={homedata?.headerdata} />
          <Slider sliderdata={homedata?.sliderdata} />
          <Scrollbar projectdata={homedata?.newsdata} />
          <About />
          <HomeSections />
          <Whoweare />
          <LatestNews projectdata={homedata?.newsdata} />
          <Youtube gallerydata={homedata?.gallerydata} />
          <GalleryImages />
          <Borderfooter />
          <Footer footerdata={homedata?.footerdata} />
        </>
      )}
    </>
  );
}

export default Home;
