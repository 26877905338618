import React, { useState, useEffect } from "react";
import axios from "axios";
import { ApiUrl } from "../../components/API/Api";
import "./css/profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const BishopProfile = () => {
  const [activeTab, setActiveTab] = useState("bishop4");
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/get/bishopprofile`)
      .then((response) => {
        setLoading(false);
        if (response.data.success && response.data.data.length > 0) {
          setProfile(response.data.data);
        } else {
          setNoData(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile:", error);
        setLoading(false);
        setNoData(true);
      });
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  if (noData) {
    return <p className="text-center font-weight-bold mt-5">No data available</p>;
  }

  return (
    <>
      <div className="vh-section-outer section-bg" id="whoweare">
        <div className="section-inner">
          <div className="container">
            <div className="section-heading">
              <h3 className="mb-4 text-center">Bishop Profile</h3>
            </div>
            <div className="row">
              <div className="tabSection">
                <ul className="nav nav-pills" role="tablist">
                  <li
                    role="presentation"
                    className={`category-link ${activeTab === "bishop4" ? "active" : ""
                      }`}
                  >
                    <a
                      href="/"
                      onClick={() => handleTabClick("bishop4")}
                      data-bs-target="#bishop4"
                      aria-controls="bishop4"
                      role="tab"
                      data-toggle="tab"
                    >
                      <p className="hidden-xs">
                        Bishop Profile <i className="fa fa-user"></i>
                      </p>
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={`category-link ${activeTab === "study1" ? "active" : ""
                      }`}
                  >
                    <a
                      href="/"
                      onClick={() => handleTabClick("study1")}
                      data-bs-target="#study1"
                      aria-controls="study1"
                      role="tab"
                      data-toggle="tab"
                    >
                      <p className="hidden-xs text-black">
                        Studies <i className="fa fa-book"></i>
                      </p>
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={`category-link ${activeTab === "offices2" ? "active" : ""
                      }`}
                  >
                    <a
                      href="/"
                      onClick={() => handleTabClick("offices2")}
                      data-bs-target="#offices2"
                      aria-controls="offices2"
                      role="tab"
                      data-toggle="tab"
                    >
                      <p className="hidden-xs text-black">
                        Offices <i className="fa fa-building"></i>
                      </p>
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className={`category-link ${activeTab === "publication3" ? "active" : ""
                      }`}
                  >
                    <a
                      href="/"
                      onClick={() => handleTabClick("publication3")}
                      data-bs-target="#publication3"
                      aria-controls="publication3"
                      role="tab"
                      data-toggle="tab"
                    >
                      <p className="hidden-xs text-black">
                        Publications <i className="fa fa-book"></i>
                      </p>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  {activeTab === "study1" && (
                    <div id="study1" className="tab-pane active">
                      <div className="row">
                        {loading ? (
                          <div className="col-lg-12 text-center">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                          </div>
                        ) : (
                          profile?.map((item, index) => (
                            <div className="col-lg-12" key={index}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.studies,
                                }}
                              ></div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                  {activeTab === "offices2" && (
                    <div id="offices2" className="tab-pane active">
                      <div className="row">
                        {loading ? (
                          <div className="col-lg-12 text-center">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                          </div>
                        ) : (
                          profile?.map((item, index) => (
                            <div className="col-lg-12" key={index}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.ministry,
                                }}
                              ></div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                  {activeTab === "publication3" && (
                    <div id="publication3" className="tab-pane active">
                      <div className="row">
                        {loading ? (
                          <div className="col-lg-12 text-center">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                          </div>
                        ) : (
                          profile?.map((item, index) => (
                            <div className="col-lg-12" key={index}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.publications,
                                }}
                              ></div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                  {activeTab === "bishop4" && (
                    <div id="bishop4" className="tab-pane active">
                      <div className="row">
                        {loading ? (
                          <div className="col-lg-12 text-center">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                          </div>
                        ) : (
                          profile?.map((bishop, index) => (
                            <>
                              <div className="col-lg-3" key={index}>
                                <img
                                  src={bishop?.file}
                                  alt={bishop?.name}
                                  className="img-fluid"
                                  id="bishopimagesnew"
                                  style={{
                                    width: "90%",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                    borderRadius: "10px",
                                    padding: "10px",
                                  }}
                                />
                              </div>
                              <div className="col-lg-9">
                                <div className="row">
                                  <div className="col-12">
                                    <h4>{bishop?.name}</h4>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <p>Bishop of Kurnool Diocese</p>
                                  </div>
                                </div>
                                <div className="row justify-content-between">
                                  <div className="col-6">
                                    <dl>
                                      <dt>
                                        <i className="fa fa-user"></i> Bishop
                                        Name:
                                      </dt>
                                      <dd>{bishop?.name || ""}</dd>

                                      <dt>
                                        <i className="fa fa-birthday-cake"></i>{" "}
                                        Date of Birth:
                                      </dt>
                                      <dd className="mt-2">
                                        {bishop?.date_of_birth || ""}
                                      </dd>
                                      <dt>
                                        <i className="fa fa-users"></i> Father
                                        Name:
                                      </dt>
                                      <dd>{bishop?.father_name || ""}</dd>
                                      <dt>
                                        <i className="fa fa-users"></i> Mother
                                        Name:
                                      </dt>
                                      <dd>{bishop?.mother_name || ""}</dd>
                                    </dl>
                                  </div>
                                  <div className="col-6">
                                    <dl>
                                      <dt>
                                        <i className="fa fa-calendar-o"></i>{" "}
                                        Ordination Date:
                                      </dt>
                                      <dd className="mt-2">
                                        {bishop?.date_of_ordination || ""}
                                      </dd>
                                      <dt>
                                        <i className="fa fa-calendar-o"></i>{" "}
                                        Consecration Date:
                                      </dt>
                                      <dd className="mt-2">
                                        {bishop?.date_of_consecration || ""}
                                      </dd>
                                      <dt>
                                        <i className="fa fa-map-marker"></i>{" "}
                                        Place of Birth:
                                      </dt>
                                      <dd>{bishop?.place_of_birth || ""}</dd>
                                      <dt>
                                        <i className="fa fa-language"></i>{" "}
                                        Languages Spoken:
                                      </dt>
                                      <dd>{bishop?.languages_spoken || ""}</dd>
                                    </dl>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BishopProfile;
