import React, { useState, useEffect } from "react";
import { Table, Form, FormControl } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../components/API/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const CollegeConsultors = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30);
  const [categoryName, setCategoryName] = useState("Members Details");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const searchParams = new URLSearchParams(location?.search);
  const CategoryFromUrlId = searchParams.get("from");

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      axios
        .get(`${ApiUrl}/get/members/${parseInt(CategoryFromUrlId)}`)
        .then((response) => {
          setLoading(false);
          setData(response?.data?.data || []);
          console.log(response?.data?.data);
          if (response?.data?.data?.length > 0) {
            if (CategoryFromUrlId !== "members") {
              setCategoryName(response?.data?.data[0]?.category_name);
            } else {
              setCategoryName("MEMBERS");
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    };

    fetchData();
  }, [CategoryFromUrlId]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filteredData = currentData.filter((item) => {
    return Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <>
      <div className="container">
        {loading && (
          <div className="text-center mt-3">
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
          </div>
        )}
        {!loading && !error && (
          <>
            {data?.length === 0 ? (
              <div className="center-message">
                <p>No Data Available</p>
              </div>
            ) : (
              <div className="table-container">
                <h3 className="heading">{categoryName}</h3>
                <p className="mb-3 mb-sm-2 font-weight-bold">Total Count : {data?.length}</p>
                <Form inline className="mb-3 mb-sm-2">
                  <FormControl
                    type="text"
                    placeholder="Search"
                    className="mr-sm-2"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </Form>

                <Table striped bordered hover>
                  <thead className="thead-dark">
                    <tr>
                      <th>S.No</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.map((item, index) => (
                      <tr key={item?.id}>
                        <td>{index + 1}</td>
                        <td>
                        <img
                            src={item?.media_url}
                            alt="members"
                            className="img-fluid"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </td>
                        <td>{item?.name}</td>
                        <td>
                          {item?.roles?.split(",")?.map((role, index) => (
                            <React.Fragment key={index}>
                              <span
                                className={`badge badge-pill ${"badge-success"}`}
                              >
                                {role}
                              </span>
                              {index < item?.roles?.split(",").length - 1 && (
                                <span>&nbsp;</span>
                              )}
                            </React.Fragment>
                          ))}
                        </td>
                        <td>{item?.address}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {/* Pagination */}
                {data?.length > itemsPerPage && (
                  <div>
                    <ul className="pagination">
                      {Array?.from({
                        length: Math?.ceil(data.length / itemsPerPage),
                      })?.map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            onClick={() => paginate(index + 1)}
                            className="page-link"
                            style={{ cursor: "pointer" }}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {error && (
          <div className="center-message">
            <p>Error fetching data: {error?.message}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CollegeConsultors;
