import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "../../components/API/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const BishopProgram = () => {
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [archivedPrograms, setArchivedPrograms] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/bishopprogram`);
        if (response?.data?.success) {
          const data = response?.data?.data;
          const currentMonth = new Date().toLocaleString("default", {
            month: "long",
            year: "numeric",
          });
          const { current, archived } = groupByMonth(data, currentMonth);
          setPrograms(current);
          setArchivedPrograms(archived);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const groupByMonth = (data, currentMonth) => {
    return data.reduce(
      (acc, program) => {
        const month = new Date(program.startdate).toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        if (month === currentMonth) {
          if (!acc.current[month]) {
            acc.current[month] = [];
          }
          acc.current[month].push(program);
        } else {
          if (!acc.archived[month]) {
            acc.archived[month] = [];
          }
          acc.archived[month].push(program);
        }
        return acc;
      },
      { current: {}, archived: {} }
    );
  };

  const sortedProgramMonths = Object.keys(programs).sort();
  const sortedArchivedProgramMonths = Object.keys(archivedPrograms).sort(
    (a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB - dateA;
    }
  );

  if (loading) {
    return (
      <div
        className="text-center mt-5"
        style={{ fontWeight: "bold", color: "black" }}
      >
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <h3 className="text-center mt-3 mb-4">Bishop's Monthly Program</h3>
        {sortedProgramMonths?.map((month) => (
          <div key={month}>
            <h4 className="text-center mt-3 mb-4">{month}</h4>
            <table className="table table-bordered table-hover table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>S.No</th>
                  <th style={{ width: "50%" }}>Event Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Day</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {[...programs[month]]
                  ?.sort(
                    (a, b) => new Date(a?.startdate) - new Date(b?.startdate)
                  )
                  ?.map((program, index) => (
                    <tr key={program?.id}>
                      <td>{index + 1}</td>
                      <td>{program?.ename}</td>
                      <td>
                        {new Date(program?.startdate)?.toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </td>
                      <td>
                        {program?.enddate
                          ? new Date(program?.enddate)?.toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )
                          : "-"}
                      </td>
                      <td>
                        {new Date(program?.startdate)?.toLocaleDateString(
                          "en-GB",
                          {
                            weekday: "long",
                          }
                        )}
                      </td>
                      <td style={{ wordBreak: "break-word" }}>
                        {new Date(program?.startdate)?.toLocaleTimeString(
                          "en-GB",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                            timeZone: "Asia/Kolkata",
                          }
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ))}

        {/* Archived programs */}
        {sortedArchivedProgramMonths.length > 0 && (
          <div>
            <h4 className="text-center mt-3 mb-4">Archived Programs</h4>
            <div className="accordion" id="archiveAccordion">
              {sortedArchivedProgramMonths.map((month, index) => (
                <div className="card" key={month}>
                  <div className="card-header" id={`heading${index}`}>
                    <h2 className="mb-0">
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src="/images/all-img/tick.png"
                          alt="nodata"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                            marginLeft: "10px",
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                            borderRadius: "50%",
                          }}
                        />
                        <button
                          className="btn btn-link btn-block text-left collapsed w-100 text-decoration-none"
                          type="button"
                          data-toggle="collapse"
                          data-target={`#collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`collapse${index}`}
                        >
                          {month}
                        </button>
                        <h6 className="text-black font-weight-bold ml-3">
                          ({archivedPrograms[month].length})
                        </h6>
                      </span>
                    </h2>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className="collapse"
                    aria-labelledby={`heading${index}`}
                    data-parent="#archiveAccordion"
                  >
                    <div className="card-body">
                      <table className="table table-striped table-bordered table-hover mt-3 mb-5">
                        <thead className="thead-dark">
                          <tr>
                            <th>S.No</th>
                            <th style={{ width: "50%" }}>Event Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Day</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {archivedPrograms[month]
                            ?.sort(
                              (a, b) =>
                                new Date(a?.startdate) - new Date(b?.startdate)
                            )
                            ?.map((program, idx) => (
                              <tr key={program?.id}>
                                <td>{idx + 1}</td>
                                <td>{program?.ename}</td>
                                <td>
                                  {new Date(
                                    program?.startdate
                                  )?.toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}
                                </td>
                                <td>
                                  {program?.enddate
                                    ? new Date(
                                        program?.enddate
                                      )?.toLocaleDateString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      })
                                    : "-"}
                                </td>
                                <td>
                                  {new Date(
                                    program?.startdate
                                  )?.toLocaleDateString("en-GB", {
                                    weekday: "long",
                                  })}
                                </td>
                                <td style={{ wordBreak: "break-word" }}>
                                  {new Date(
                                    program?.startdate
                                  )?.toLocaleTimeString("en-GB", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                    timeZone: "Asia/Kolkata",
                                  })}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BishopProgram;
