import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../../components/API/Api';
import Pagination from 'react-bootstrap/Pagination';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const Seminarians = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/get/kurnooldiocese/preist/7?page=${currentPage}&limit=${itemsPerPage}`);
                setData(response?.data?.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentData = data.slice(firstIndex, lastIndex);

    return (
        <div className="container">
            <h3 className="text-center mt-3 mb-3">
                Seminarians</h3>
            {loading ? (
                <p className="text-center font-weight-bold"><FontAwesomeIcon icon={faSpinner} spin size="2x" /></p>
            ) : (
                <table className="table table-striped table-bordered" style={{ width: '100%' }}>
                    <thead className="thead-dark">
                        <tr>
                            <th>S.No</th>
                            <th>Name of the Seminaraian</th>
                            <th>Year</th>
                            <th>Place of Study</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.length > 0 ? (
                            currentData?.map((item, index) => (
                                <tr key={index}>
                                    <td>{firstIndex + index + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.year_of_study}</td>
                                    <td>{item?.place_of_study}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center font-weight-bold text-danger">No Record found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
            <Pagination className="mt-3" size="sm">
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                    <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / itemsPerPage)} />
            </Pagination>
        </div>
    );
};

export default Seminarians;