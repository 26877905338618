import React from 'react'

const Borderfooter = () => {
  return (
    <>
      <div className="footer-design-container">
        <div className="footer-design">
          <div className="footer-design-1" />
        </div>
        <div className="footer-design">
          <div className="footer-design-2" />
        </div>
        <div className="footer-design">
          <div className="footer-design-3" />
        </div>
        <div className="footer-design">
          <div className="footer-design-4" />
        </div>
        <div className="footer-design">
          <div className="footer-design-5" />
        </div>
        <div className="footer-design">
          <div className="footer-design-6" />
        </div>
      </div> 
    </>
  )
}

export default Borderfooter
