import React from 'react'

const ReligiousSisters = () => {
  return (
    <>
      <div className="container">
        <h3 className="text-center mt-3 mb-3">Religious Sisters</h3>
        <p className="text-center font-weight-bold">Under Construction</p>
      </div>
    </>
  )
}

export default ReligiousSisters
